// src/NavBar.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './navbar.css';

const NavBar = ({ authData, setAuthData }) => {
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('authData');
    setAuthData(null);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">🛒 Planogram</Link>
      </div>

      <div className="navbar-links">
        {authData ? (
          <>
            <Link to="/">Home</Link>
            {/* {location.pathname !== '/' && (
              <span className="breadcrumb">
                <Link to="/">Shelf Map</Link>
                <span> &gt; </span>
                <span>{location.pathname.split('/').pop()}</span>
              </span>
            )} */}
            <button onClick={handleLogout} className="logout-button">
              Logout
            </button>
          </>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
