import React, { useState } from "react";
import axios from "axios";
import "./login.css";
import { useNavigate } from "react-router-dom";
import config from "../../services/config";

const Login = ({ setAuthData }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState({
    message: "",
    type: "", // success or failure
  });
  const [showNotification, setShowNotification] = useState(false); // Controls notification visibility
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setNotification({ message: "", type: "" }); // Reset notification
    setShowNotification(false); // Hide previous notification

    try {
        const response = await axios.post(`${config.apiBaseUrl}/api/login`, {
        username,
        password,
      });

      const authData = response.data;
      setAuthData(authData);
      localStorage.setItem("authData", JSON.stringify(authData));

      // Show success notification
      setNotification({ message: "Successfully logged in!", type: "success" });
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false); // Hide notification after 3 seconds
        navigate("/shops"); // Redirect to home page after 3 seconds
      }, 3000);
    } catch (err) {
      console.error("Login error:", err);

      // Set the error message and show a failure notification
      const errorMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : "An unknown error occurred.";

      setNotification({ message: `Failed: ${errorMessage}`, type: "failure" });
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false); // Hide notification after 3 seconds
      }, 3000);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <div className="frame">
          <div className="div">
            <div className="text-wrapper">Login</div>
            <p className="p">Please enter your login information</p>
          </div>
          <div className="div-2">
            <div className="div-3">
              <div className="input">
                <div className="or-wrapper">
                  <div className="or-2">Username</div>
                </div>
                <div className="frame-wrapper">
                  <input
                    className="full-name"
                    placeholder="Enter your username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    autoComplete="username"
                  />
                </div>
              </div>
              <div className="input">
                <div className="or-wrapper">
                  <div className="or-2">Password</div>
                </div>
                <div className="frame-wrapper">
                  <div className="full-name-wrapper">
                    <input
                      className="full-name"
                      placeholder="Enter your password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      autoComplete="current-password"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="div-4">
              <div className="horizontal-with"></div>
            </div>
          </div>
          <button type="submit" className="button">
            <div className="text">Login</div>
          </button>
        </div>
      </form>

      {/* Popup notification */}
      <div
        className={`notification ${
          showNotification ? "notification-show" : ""
        } ${
          notification.type === "success"
            ? "notification-success"
            : "notification-failure"
        }`}
      >
        {notification.message}
      </div>
    </div>
  );
};

export default Login;
