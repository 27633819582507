// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ShelfMap from './pages/ShelfMap/ShelfMap';
import ShelfDetail from './pages/ShelfDetail/ShelfDetail';
import NavBar from './components/NavBar/NavBar';
import Login from './pages/Login/Login';
import Shops from './pages/Shops/Shops'; // Import the Shops component

const App = () => {
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    // Check if auth data is stored in localStorage
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      setAuthData(JSON.parse(storedAuthData));
    }
  }, []);

  return (
    <Router>
      <NavBar authData={authData} setAuthData={setAuthData} />
      <Routes>
      <Route
          path="/"
          element={!authData ? <Login setAuthData={setAuthData} /> : <Navigate to="/login" />}
        />
        <Route
          path="/login"
          element={!authData ? <Login setAuthData={setAuthData} /> : <Navigate to="/shops" />}
        />
        <Route
          path="/shelfmap"
          element={authData ? <ShelfMap authData={authData} /> : <Navigate to="/login" />}
        />
        <Route
          path="/shops"
          element={authData ? <Shops authData={authData} setAuthData={setAuthData} /> : <Navigate to="/login" />}
        />
        <Route
          path="/shelfdetail"
          element={authData ? <ShelfDetail authData={authData} /> : <Navigate to="/login" />}
        />
        <Route
          path="/shelf/:shelfName"
          element={authData ? <ShelfDetail authData={authData} /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
};

export default App;
