import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ImageShelfDetailLoader.css";
import config from "../../services/config";

const ImageShelfDetailLoader = ({ file, manualMatchingEnabled, shopId }) => {
  const { name, compliant, mainImage, comparisonResult } = file;
  const [manualStatus, setManualStatus] = useState({});
  const [manualMatchingPercentage, setManualMatchingPercentage] = useState(0);
  const [barcodeNameMap, setBarcodeNameMap] = useState({});

  // Fetch product names
  useEffect(() => {
    if (comparisonResult && comparisonResult.comparisons) {
      // Collect all unique barcodes
      const barcodesSet = new Set();
      comparisonResult.comparisons.forEach((comparison) => {
        if (comparison.expected) barcodesSet.add(comparison.expected);
        if (comparison.detected && comparison.detected !== 'N/A') {
          barcodesSet.add(comparison.detected);
        }
      });
      const barcodes = Array.from(barcodesSet);

      // Fetch product names
      const fetchProductNames = async () => {
        try {
          const response = await axios.post(
            `${config.apiBaseUrl}/api/product-names`,
            {
              barcodes,
              shopId,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authData")}`,
              },
            }
          );
          const { barcodeNameMap } = response.data;
          setBarcodeNameMap(barcodeNameMap);
        } catch (error) {
          console.error("Error fetching product names:", error);
        }
      };
      fetchProductNames();
    }
  }, [comparisonResult, shopId]);

  // Function to toggle manual status (Yes/No) for each product
  const toggleManualStatus = (position) => {
    setManualStatus((prevState) => ({
      ...prevState,
      [position]: !prevState[position],
    }));
  };

  // Calculate the manual matching percentage based on the manual status
  useEffect(() => {
    if (comparisonResult && comparisonResult.comparisons) {
      const totalProducts = comparisonResult.comparisons.length;
      const manualMatches = comparisonResult.comparisons.filter(
        (comp) => manualStatus[comp.position]
      ).length;

      const percentage = (manualMatches / totalProducts) * 100;

      // Only update the manual matching percentage if manual matching is enabled
      if (manualMatchingEnabled) {
        setManualMatchingPercentage(percentage.toFixed(2));
      } else {
        setManualMatchingPercentage(0); // Reset manual matching percentage when disabled
      }
    }
  }, [manualStatus, comparisonResult, manualMatchingEnabled]);

  if (!mainImage || !comparisonResult) {
    return <div>Loading Image Data...</div>; // Display loading message if data is missing
  }

  return (
    <div className="planogram-item">
      {/* Compliance Status */}
      <div className={`status ${compliant ? "compliant" : "not-compliant"}`}>
        {compliant ? "The Shelf is Compliant" : "The Shelf is Not Compliant"}
      </div>

      {/* Flex Container for Image and Table */}
      <div className="content-container">
        {/* Image Display */}
        <div className="image-container">
          <img src={mainImage} alt={name} className="main-image" />
        </div>

        {/* Comparison Results Table */}
        {comparisonResult && (
          <div className="comparison-results">
            <table className="shops-table">
              <caption>
                <h3>Comparison Summary</h3>
              </caption>
              <tbody>
                <tr>
                  <td><strong>Total Physical Products:</strong></td>
                  <td>{comparisonResult.totalBarcodes}</td>
                </tr>
                <tr>
                  <td><strong>Total Identified Products:</strong></td>
                  <td>{comparisonResult.totalMatches}</td>
                </tr>
                <tr>
                  <td><strong>Total Non Identified Products:</strong></td>
                  <td>{comparisonResult.totalNonMatches}</td>
                </tr>
                <tr>
                  <td><strong>Matching Accuracy:</strong></td>
                  <td>{comparisonResult.matchingPercentage}%</td>
                </tr>

                {manualMatchingEnabled && (
                  <tr>
                    <td><strong>Manual Matching Accuracy:</strong></td>
                    <td>{manualMatchingPercentage}%</td>
                  </tr>
                )}
              </tbody>
            </table>
            <br />
            <table className="shops-table">
              <thead>
                <tr>
                  <th>Expected Product</th>
                  <th>Detected Product</th>
                  <th>Status</th>

                  {/* Conditionally render the Manual Status column if manual matching is enabled */}
                  {manualMatchingEnabled && <th>Manual Status</th>}
                </tr>
              </thead>
              <tbody>
                {comparisonResult?.comparisons?.map((comparison) => (
                  <tr key={comparison.position}>
                    <td>
                      {barcodeNameMap[comparison.expected] && (
                        <div className="product-name">
                          {barcodeNameMap[comparison.expected]}
                        </div>
                      )}
                      <div className="barcode">
                        <b>{comparison.expected}</b>
                      </div>
                    </td>
                    <td>
                      {comparison.detected && comparison.detected !== "N/A" ? (
                        <>
                          {barcodeNameMap[comparison.detected] && (
                            <div className="product-name">
                              {barcodeNameMap[comparison.detected]}
                            </div>
                          )}
                          <div className="barcode">
                            <b>{comparison.detected}</b>
                          </div>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td className={comparison.status === "Match" ? "match" : "non-match"}>
                      {comparison.status}
                    </td>

                    {manualMatchingEnabled && (
                      <td>
                        <button
                          className={
                            manualStatus[comparison.position] ? "yes-button" : "no-button"
                          }
                          onClick={() => toggleManualStatus(comparison.position)}
                        >
                          {manualStatus[comparison.position] ? "Yes" : "No"}
                        </button>
                      </td>
                    )}
                  </tr>
                )) || (
                  <tr><td colSpan="4">No comparisons available</td></tr>  // Fallback for empty comparisons
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageShelfDetailLoader;
