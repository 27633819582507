// src/ShelfMap.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./shelfMap.css";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../services/config";

const ShelfMap = ({ authData }) => {
  const [shelves, setShelves] = useState([]);
  const [shapesData, setShapesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const shopName = location.state?.shopName || "";
  const tenantName = location.state?.tenantName || "";
  const shopId = location.state?.shopId || "";

  // Tooltip state
  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    text: "",
  });

  // Function to generate color based on shelfName
  const generateColor = (shelfName) => {
    let hash = 0;
    for (let i = 0; i < shelfName.length; i++) {
      hash = shelfName.charCodeAt(i) + ((hash << 5) - hash);
    }
    // return `hsl(${hash % 360}, 70%, 70%)`;
    return `hsl(28, 100%, 58%)`;
  };

  useEffect(() => {

    fetchFloorplan();
    fetchShelves();

  }, [shopName, tenantName]);

  const fetchShelves = async () => {
    if (!shopName || !tenantName) {
      setError("Shop name or tenant name not provided. Cannot fetch shelves.");
      setLoading(false);
      return;
    }
  
    try {
      const response = await axios.get(`${config.apiBaseUrl}/api/shelves`, {
        params: { shopName: shopName, tenantName: tenantName },
      });
      const data = response.data || {};
      const fetchedShelves = data.shelves || [];
      setShelves(fetchedShelves);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching shelves:", err.message);
      setError("Failed to load shelves. Please try again later.");
      setLoading(false);
    }
  };
  
  const fetchFloorplan = async () => {
    if (!shopName || !tenantName) {
      setError("Shop name or tenant name not provided. Cannot fetch floorplan.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${config.apiBaseUrl}/api/floorplan`, {
        params: { shopName, tenantName },
      });
      setShapesData(response.data.floorplan);
    } catch (err) {
      console.error("Error fetching floorplan:", err.message);
      setError("Failed to load floorplan. Please make sure it is uploaded to the cloud.");
    }
  };

  // Handle tooltip events
  const handleMouseEnter = (e, shape, shelfExists) => {
    setTooltip({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      text: shelfExists ? shape.shelfName : "No shelf image",
    });
  };

  const handleMouseMove = (e) => {
    setTooltip((prevTooltip) => ({
      ...prevTooltip,
      x: e.clientX,
      y: e.clientY,
    }));
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, x: 0, y: 0, text: "" });
  };

  // Handle click on a shelf shape
  const handleShelfClick = (shelf, shelfExists) => {

    if (shelfExists)
    {
          // Extract the relative path from the S3 URL
    let relativePath = shelf.path.split(".com/")[1]; // Only keep the part after ".com/"
    
    // Remove the image file extension (.jpeg, .jpg, .png)
    relativePath = relativePath.replace(/\.(jpeg|jpg|png)$/, "");
      navigate(`/shelf/${encodeURIComponent(shelf.name)}`, {
        state: { path: relativePath, name: shelf.name, shopName: shopName, tenantName: tenantName, shopId: shopId },
      });
    }else{
      // Do nothing
    }
  
    
  };
  

  if (loading) {
    return (
      <div className="shelf-map">
        <p>Loading the floor plan...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="shelf-map">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="shelf-map">
      <h2>Floor Plan</h2>
      {/* SVG Floor Map */}
      <div className="svg-floor-map-container">
        <svg width="700" height="500" className="svg-floor-map">
          {shapesData.map((shape) => {
            // Find the corresponding shelf from the loaded shelves
            const shelf = shelves.find((s) => s.name === shape.shelfName);

            // Determine the color
            const color = shelf
              ? generateColor(shape.shelfName)
              : "#C0C0C0"; // Grey color for unmapped shelves

            return (
              <g
                key={shape.id}
                onClick={() => handleShelfClick(shelf,shelf)}
                className="svg-shape-group"
                style={{ cursor: shelf ? "pointer" : "default" }}
              >
                <rect
                  x={shape.x}
                  y={shape.y}
                  width={
                    shape.type === "rectangle" ? shape.width : shape.size
                  }
                  height={
                    shape.type === "rectangle" ? shape.height : shape.size
                  }
                  fill={color}
                  stroke="#000"
                  className="svg-shape"
                  data-shelf-name={shape.shelfName}
                  onMouseEnter={(e) => handleMouseEnter(e, shape, !!shelf)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                />
                <text
                  x={
                    shape.type === "rectangle"
                      ? shape.x + shape.width / 2
                      : shape.x + shape.size / 2
                  }
                  y={
                    shape.type === "rectangle"
                      ? shape.y + shape.height / 2
                      : shape.y + shape.size / 2
                  }
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize="12"
                  fill="#000"
                  className="svg-text"
                >
                  {shape.shelfName}
                </text>
              </g>
            );
          })}
        </svg>
      </div>

      {/* Tooltip */}
      {tooltip.visible && (
        <div
          className="tooltip"
          style={{
            top: tooltip.y + 10,
            left: tooltip.x + 10,
            padding: "5px",
            borderRadius: "4px",
          }}
        >
          {tooltip.text}
        </div>
      )}
    </div>
  );
};

export default ShelfMap;
