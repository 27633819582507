import React, { useEffect, useState } from "react";
import './shops.css';
import { useNavigate } from "react-router-dom";
import config from "../../services/config";
import noImagePlaceholder from '../../services/nologo.png';

const Shops = ({ authData, setAuthData }) => {
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1); // Current page number
  const [pageSize, setPageSize] = useState(10); // Number of items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of shops
  const navigate = useNavigate();

  useEffect(() => {
    if (authData && authData.access_token) {
      fetchShops(page, pageSize, authData.access_token);
    }
  }, [page, pageSize, authData]);

  const fetchShops = async (page, limit, token) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiBaseUrl}/api/shops?limit=${limit}&skip=${(page - 1) * limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the header
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch shops: ${response.statusText}`);
      }

      const data = await response.json();
      const items = data.data.items || [];
      const total = data.data.total || 0;
      setShops(items);
      setTotalItems(total);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching shops:", err.message);
      setError("Failed to load shops. Please try again later.");
      setLoading(false);
    }
  };

  const handleShopClick = (shop) => {
    navigate(`/shelfmap`, {
      state: { shopName: shop.name, tenantName: shop.tenantName, shopId: shop.id },
    });
  };

  // Pagination handlers
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1); // Reset to first page when page size changes
  };

  if (loading) {
    return <div className="shops-container"><p>Loading shops...</p></div>;
  }

  if (error) {
    return <div className="shops-container"><p>{error}</p></div>;
  }

  return (
    <div className="shops-container">
      <h2>Shops</h2>
      <table className="shops-table">
        <thead>
          <tr>
            <th>Logo</th>
            <th>Name</th>
            <th>Address</th>
            <th>Integration System</th>
            <th>Tenant Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {shops.map((shop, index) => (
            <tr key={index} onClick={() => handleShopClick(shop)}> 
              <td><img 
                  src={shop.logoUrl || noImagePlaceholder} // If no image, use placeholder
                  alt={shop.name} 
                  className="shop-logo" 
                /></td>
              <td>{shop.name}</td>
              <td>{shop.address}</td>
              <td>{shop.integrationSystemName}</td>
              <td>{shop.tenantName}</td>
              <td>{shop.email}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="7">
              <div className="pagination-controls">
                <label>
                  Items per page: &nbsp;
                  <select value={pageSize} onChange={handlePageSizeChange}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                  </select>
                </label>
                <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>Previous</button>
                <span>Page {page}</span>
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page * pageSize >= totalItems}
                >
                  Next
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Shops;
