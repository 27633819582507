import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ShelfDetail.css";
import ImageShelfDetailLoader from "../../components/ImageLoader/ImageShelfDetailLoader";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import config from "../../services/config";

const ShelfDetail = () => {
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true); // Handle loading state
  const [error, setError] = useState(null); // Handle errors
  const [manualMatchingEnabled, setManualMatchingEnabled] = useState(false); // State for manual check
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0); // State to cycle through messages
  const { shelfName } = useParams();
  const location = useLocation();
  const shopName = location.state?.shopName || "";
  const tenantName = location.state?.tenantName || "";
  const shopId = location.state?.shopId || "";
  const navigate = useNavigate();

  const loadingMessages = [
    "Passing the image to the ML pipeline...",
    "Adding the bounding boxes on the images...",
    "Products are being recognized...",
    "Teaching robots to see...",
    "Counting pixels, please wait...",
    "Drawing some magic boxes...",
    "Asking the AI to think hard...",
    // "Finding Waldo in the image...",
    // "Convincing the pixels to behave...",
    "Consulting the neural network...",
    "Adjusting the machine's glasses...",
    // "Loading... but with style...",
    "Telling the AI to take a deep breath...",
    // "Giving the pixels a pep talk...",
    // "Translating binary into English...",
    "Polishing the image for a clearer view...",
    "Teaching AI to recognize products... again...",
    "Waking up the data scientist inside...",
    // "Making sure all the bytes are aligned...",
    "Asking the AI if it's sure about that...",
  ];

  const goBackToShelfMap = () => {
    navigate("/shelfmap", {
      state: { shopName, tenantName, shopId },
    });
  };

  useEffect(() => {
    if (tenantName && shopName && shelfName) {
      fetchImageAndLabels(tenantName, shopName, shelfName);
    } else {
      console.error("Missing tenantName, shopName, or shelfName.");
      navigate("/");
    }
  }, [tenantName, shopName, shelfName, navigate]);

  useEffect(() => {
    let timeout;
    const updateMessage = () => {
      setLoadingMessageIndex(
        (prevIndex) => (prevIndex + 1) % loadingMessages.length
      );
      const delay = 5000; // Random delay between 1 and 3 seconds
      timeout = setTimeout(updateMessage, delay);
    };

    updateMessage(); // Start the first update

    return () => clearTimeout(timeout); // Clear timeout on component unmount
  }, []);

  const fetchImageAndLabels = async (tenantName, shopName, shelfName) => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/process-images`,
        {
          images: [{ tenantName, shopName, shelfName }],
        }
      );

      const data = response.data.results[0];
      if (data) {
        setImageData({
          base64Image: data.base64Image,
          labels: data.results.map((result) => result.label),
          comparisonResult: data.comparisonResult,
        });
      }
      setLoading(false); // Stop loading after data is set
    } catch (error) {
      console.error("Error fetching image and labels:", error);
      setError("Error loading image data.");
      setLoading(false); // Stop loading even if there's an error
    }
  };

  const handleManualCheckToggle = () => {
    setManualMatchingEnabled(!manualMatchingEnabled);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-text">
          {loadingMessages[loadingMessageIndex]}
        </div>
        <div className="spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="shelf-detail">

      <div className="back-button color-span" onClick={goBackToShelfMap}>
        <span className="back-arrow">←</span> {/* Simple text arrow */}
        <span>Go back to {shopName} FloorPlan</span>
      </div>

      <label className="toggle-switch">
      <span className="color-span">Enable Manual Matching</span>
        <input
          type="checkbox"
          checked={manualMatchingEnabled}
          onChange={handleManualCheckToggle}
        />
        <span className="slider color-span"></span>
      </label>
      {imageData && imageData.comparisonResult && (
        <ImageShelfDetailLoader
          file={{
            name: shelfName,
            mainImage: `data:image/png;base64,${imageData.base64Image}`,
            compliant: parseFloat(imageData.comparisonResult.matchingPercentage) >= 80.0,
            comparisonResult: imageData.comparisonResult,
          }}
          manualMatchingEnabled={manualMatchingEnabled} // Pass down the manual matching toggle state
          shopId={shopId}
        />
      )}
    </div>
  );
};

export default ShelfDetail;
